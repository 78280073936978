/* eslint-disable react/prop-types */
import React from "react"
import { Link } from "gatsby"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { Fragment } from "react"
import { Footer } from "../components"

import "../assets/css/intro.css"
import LogoPurple from "../assets/svg/hlogo.inline.svg"

function HomePage() {
  return (
    <Fragment>
      <div>
        <Seo title="Hossmann AG" />

        <section className="grid items-center h-screen bg-white">
          <div className="col-start-1 row-start-1">
            <div className="m-auto text-center">
              <LogoPurple className="inset-0 w-16 m-auto md:w-24" />
              <h1 className="my-16 text-2xl leading-relaxed text-gray-800 h2 md:text-5xl ">
                Handwerk schafft Mehrwert. Sichtbar und spürbar.
              </h1>
              <div className="opacity-0 fade-in">
                <p className="text-base font-normal tracking-widest text-gray-700 uppercase">
                  <Link to="/switch/">Zur Webseite</Link>
                </p>
                <div className="inset-0 w-16 m-auto mt-3 border-b-3 border-secondary h-18"></div>
              </div>
            </div>
          </div>
          <StaticImage
            src="../assets/images/mood/handwerk-leistungen-stucco-1.jpg"
            alt=""
            className="object-cover object-center h-full col-start-1 row-start-1 pointer-events-none opacity-30"
          />
        </section>
      </div>
      <Footer />
    </Fragment>
  )
}

export default HomePage